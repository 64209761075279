import * as THREE from 'three'
import Experience from '../../Experience.js'

export default class MiddleLogo
{
    constructor(vertexShader, fragmentShader, uniforms)
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time

        // Resource
        this.resource = this.resources.items.middleLogoModel

        this.vertexShader = vertexShader
        this.fragmentShader = fragmentShader
        this.uniforms = uniforms
        this.setMaterial()
        this.setModel()
    }

    setMaterial()
    {
        this.material = new THREE.ShaderMaterial({
            transparent: true,
            vertexShader: this.vertexShader,
            fragmentShader: this.fragmentShader,
            uniforms: this.uniforms,
            defines: 
            {
                PR: Math.min(window.devicePixelRatio.toFixed(1), 2)
            }
        })
    }

    setModel()
    {
        this.model = this.resource.scene
        this.model.rotation.x = Math.PI / 2
        this.model.material = this.material
        this.scene.add(this.model)

        this.model.traverse((child) =>
        {
            if(child instanceof THREE.Mesh)
            {
                child.castShadow = true
                child.material = this.material
            }
        })
    }

    update()
    {
    }
}