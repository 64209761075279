export default [
    {
        name: 'topLogoModel',
        type: 'gltfModel',
        path: 'model/Top.glb'
    },
    {
        name: 'middleLogoModel',
        type: 'gltfModel',
        path: 'model/Middle.glb'
    },
    {
        name: 'bottomLogoModel',
        type: 'gltfModel',
        path: 'model/Bottom.glb'
    },
    {
        name: 'PPNeueMontrealMedium',
        type: 'font',
        path: 'fonts/PPNeueMontrealMedium.json'
    }
]