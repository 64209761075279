import * as THREE from 'three'
import Experience from './Experience.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'

export default class Camera
{
    constructor()
    {
        this.experience = new Experience()
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.canvas = this.experience.canvas
        this.isMobile = this.experience.isMobile
        this.mousePos = {
            x: 0,
            y: 0
        }

        this.setInstance()
        this.heightToFit = this.heightAtZDepth()
        this.widthToFit = this.widthAtZDepth()

        if(!this.isMobile)
        {
            window.addEventListener('mousemove', event => this.onMouseMove(event))
        }
    }

    lerp(a, b, t) 
    {
        return a * (1-t) + b * t
    }

    setInstance()
    {
        this.instance = new THREE.PerspectiveCamera(35, this.sizes.aspectRatio, 0.01, 100)
        this.instance.position.set(0, 0, 2.25)
        this.scene.add(this.instance)
    }

    onMouseMove(event)
    {
        this.mousePos.x = - (event.clientX / this.sizes.width - 0.5) * .5
        this.mousePos.y = (event.clientY / this.sizes.height - 0.5) * .5
    }

    heightAtZDepth(z = 0)
    {
        // vertical fov in radians
        const vFOV = this.instance.fov * Math.PI / 180 
        // Math.abs to ensure the result is always positive
        return 2 * Math.tan( vFOV / 2 ) * Math.abs(this.instance.position.z - z)
    }

    widthAtZDepth(z = 0)
    {
        const height = this.heightAtZDepth(z)
        return height * this.instance.aspect
    }

    resize()
    {
        this.instance.aspect = this.sizes.aspectRatio
        this.instance.updateProjectionMatrix()
    }

    update()
    {
        this.instance.position.x = this.lerp(this.instance.position.x, this.mousePos.x, .05)
        this.instance.position.y = this.lerp(this.instance.position.y, this.mousePos.y, .05)

        this.instance.rotation.z = this.lerp(this.instance.rotation.z, this.mousePos.x * 5, .05)

        this.instance.lookAt(0, 0, 0)
    }
}