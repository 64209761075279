import * as THREE from 'three'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'
import Experience from '../Experience.js'

export default class Text
{
    constructor(text, textSize, font)
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time

        this.setGeometry(text, textSize, font)
        this.setMaterial()
        this.setMesh()
    }

    setGeometry(text, textSize, font)
    {
        this.geometry = new TextGeometry(text, {
            font: font,
            size: textSize,
            height: 0,
            curveSegments: 32
        })
        this.geometry.center()
    }

    setMaterial()
    {
        this.material = new THREE.MeshBasicMaterial({
            color: 0xFAEDE4
        })
    }

    setMesh()
    {
        this.mesh = new THREE.Mesh(this.geometry, this.material)
        this.scene.add(this.mesh)
    }

    update()
    {
    }
}