import { gsap } from 'gsap/all'
import { CustomEase } from 'gsap/all'
import Experience from '../Experience.js'
import Optimo from './Optimo.js'

export default class World
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.isLoaded = false
        gsap.registerPlugin(CustomEase)

        this.progressRatio = 0
        this.loadingPercentage = document.querySelector('.loading .percentage')

        // Wait for resources
        this.resources.on('ready', () =>
        {
            // Setup
            this.optimo = new Optimo()
        })
    }

    update()
    {
        if(!this.isLoaded)
        {
            this.progressRatio = Math.min(this.progressRatio + (Math.floor(Math.random() * 4) / 100), 1)

            this.loadingPercentage.innerHTML = Math.round(this.progressRatio * 100)

            gsap.to('.loading .percentage', {
                scale:  1.1 - this.progressRatio
            })

            gsap.to('.loading .bar', {
                scaleX: this.progressRatio
            })
        }

        if(this.progressRatio === 1 && !this.isLoaded)
        {
            this.isLoaded = true

            gsap.to('.loading .percentage', {
                scale: 0,
                duration: .25,
                delay: .5,
                ease: '0.76, 0, 0.24, 1'
            })

            gsap.to('.loading .bar', {
                scaleX: 0,
                duration: .25,
                delay: .5,
                ease: '0.76, 0, 0.24, 1',
                onComplete: () =>
                {
                    setTimeout(() =>
                    {
                        gsap.set('.loading', {
                            display: 'none'
                        })

                        if(this.optimo)
                        {
                            this.optimo.intro()
                        }
                    }, 250)
                }
            })
        }

        if(this.optimo)
        {
            this.optimo.update()
        }
    }
}