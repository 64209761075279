import * as THREE from 'three'
import Experience from '../Experience.js'
import { gsap, CustomEase } from 'gsap/all'
import 'splitting/dist/splitting.css'
import 'splitting/dist/splitting-cells.css'
import Splitting from 'splitting'
import Text from './Text.js'
import TopLogo from './Logo/TopLogo.js'
import MiddleLogo from './Logo/MiddleLogo.js'
import BottomLogo from './Logo/BottomLogo.js'
import vertexShader from '../Shaders/vertex.glsl'
import fragmentShader from '../Shaders/fragment.glsl'

export default class Optimo
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.sizes = this.experience.sizes
        this.resources = this.experience.resources
        this.camera = this.experience.camera
        this.renderer = this.experience.renderer
        this.isMobile = this.experience.isMobile
        gsap.registerPlugin(CustomEase)
        CustomEase.create('easeIntro', '0.16, 1, 0.3, 1')
        CustomEase.create('ease', '0.65, 0, 0.35, 1')

        // Text splittings
        Splitting({target: 'body > .little-paragraph:first-of-type', by: 'chars'})

        // isMobile ?
        if(this.isMobile)
        {
            document.querySelector('.links').remove()
            document.querySelector('.capabilities').remove()
        }
        else
        {
            document.querySelector('.mobile-links').remove()

            // Text splittings
            Splitting({target: '.capabilities', by: 'chars'})
    
            // Links hover animation
            this.links = document.querySelectorAll('.links li a')
            this.linksOuterButtonWidths = []
            this.linksInnerButtonWidths = []
    
            this.links.forEach((link, index) =>
            {
                this.linksOuterButtonWidths[index] = link.children[0].children[0].offsetWidth + 44
                this.linksInnerButtonWidths[index] = link.children[1].offsetWidth
    
                gsap.set(link.children[0], {
                    maxWidth: `${this.linksInnerButtonWidths[index]}px`
                })
    
                link.addEventListener('mouseover', () =>
                {
                    gsap.set(link.children[0], {
                        maxWidth: `${this.linksOuterButtonWidths[index]}px`
                    })
                })
    
                link.addEventListener('mouseleave', () =>
                {
                    gsap.set(link.children[0], {
                        maxWidth: `${this.linksInnerButtonWidths[index]}px`
                    })
                })
            })
        }

        // Setup
        this.backRenderTarget = new THREE.WebGLRenderTarget(this.sizes.width, this.sizes.height)
        this.mainRenderTarget = new THREE.WebGLRenderTarget(this.sizes.width, this.sizes.height)
        this.text = new Text('OPTIMO', this.camera.widthAtZDepth() * .95 / 5.310999696904962, this.resources.items['PPNeueMontrealMedium'])

        this.logoUniforms = {
            uTexture: { value: null },
            uIorR: { value: 1.15 },
            uIorY: { value: 1.16 },
            uIorG: { value: 1.18 },
            uIorC: { value: 1.22 },
            uIorB: { value: 1.22 },
            uIorP: { value: 1.22 },
            uRefractPower: { value: .5 },
            uChromaticAberration: { value: .5 },
            uSaturation: { value: 1.025 },
            uShininess: { value: 60.0 },
            uDiffuseness: { value: 0.2 },
            uFresnelPower: { value: 8.0 },
            uLight: { value: new THREE.Vector3(0.0, 3.0, 1.0) },
            winResolution: { value: new THREE.Vector2(this.sizes.width, this.sizes.height).multiplyScalar(Math.min(window.devicePixelRatio, 2)) }
        }
        this.topLogo = new TopLogo(vertexShader, fragmentShader, this.logoUniforms)
        this.middleLogo = new MiddleLogo(vertexShader, fragmentShader, this.logoUniforms)
        this.bottomLogo = new BottomLogo(vertexShader, fragmentShader, this.logoUniforms)

        this.logo = new THREE.Group()
        this.logo.add(this.topLogo.model)
        this.logo.add(this.middleLogo.model)
        this.logo.add(this.bottomLogo.model)
        this.scene.add(this.logo)

        // Responsive
        this.logo.position.z = .75
        this.bottomLogo.model.children[0].geometry.computeBoundingBox()

        this.logoScaleRatio = this.camera.widthAtZDepth(this.logo.position.z) * 0.6 / (this.bottomLogo.model.children[0].geometry.boundingBox.max.x * 2)

        this.logo.scale.x = this.logoScaleRatio
        this.logo.scale.y = this.logoScaleRatio
        this.logo.scale.z = this.logoScaleRatio
    }

    intro()
    {
        gsap.to('.webgl', {
            opacity: 1,
            duration: 1,
            ease: 'easeIntro',
            onComplete: () => 
            {
                this.animation()
            }
        })

        gsap.fromTo(this.camera.instance.position, {
            z: 4
        },
        {
            z: 2.25,
            duration: 1,
            ease: 'easeIntro',
        })

        gsap.to('.logo', {
            opacity: 1,
            duration: 1,
            delay: .5,
            ease: '0.5, 1, 0.89, 1'
        })

        gsap.to('.little-paragraph .char', {
            opacity: 1,
            transform: 'translate3d(0, 0, 0) rotateY(0) skewY(0)',
            delay: .5,
            ease: '0.5, 1, 0.89, 1',
            stagger: {
                amount: .25
            }
        })

        gsap.to('.paragraph .line', {
            opacity: 1,
            transform: 'translate3d(0, 0, 0) rotateX(0) skew(0)',
            delay: .5,
            ease: '0.5, 1, 0.89, 1',
            stagger: {
                amount: .25
            }
        })

        gsap.to('.little-paragraph-container .little-paragraph', {
            opacity: 1,
            transform: 'translateY(0) rotateX(0) skew(0)',
            delay: .5,
            ease: '0.5, 1, 0.89, 1',
            stagger: {
                amount: .25
            }
        })

        if(!this.isMobile)
        {
            gsap.to('.links a', {
                opacity: 1,
                duration: 1,
                delay: .5,
                ease: '0.5, 1, 0.89, 1'
            })

            gsap.to('.capabilities .char', {
                opacity: 1,
                transform: 'translateX(0) rotateY(0) skewY(0)',
                delay: .5,
                ease: '0.5, 1, 0.89, 1',
                stagger: {
                    amount: .25
                }
            })

            gsap.to('.capabilities .arrow', {
                transform: 'scale(1)',
                duration: 1,
                delay: .5,
                ease: 'easeIntro'
            })
        }
        else
        {
            gsap.to('.mobile-links a', {
                opacity: 1,
                duration: 1,
                delay: .5,
                ease: '0.5, 1, 0.89, 1'
            })

            gsap.to('.mobile-links .mobile-capabilities', {
                transform: 'scale(1)',
                duration: 1,
                delay: .5,
                ease: 'easeIntro'
            })
        }
    }

    animation()
    {
        gsap.fromTo(this.topLogo.model.rotation, {
            z: 0
        },
        {
            z: -Math.PI * 2,
            duration: 5,
            ease: 'ease'
        })

        gsap.fromTo(this.middleLogo.model.rotation, {
            z: 0
        },
        {
            z: -Math.PI * 2,
            duration: 5,
            delay: .25,
            ease: 'ease'
        })

        gsap.fromTo(this.bottomLogo.model.rotation, {
            z: 0
        },
        {
            z: -Math.PI * 2,
            duration: 5,
            delay: .5,
            ease: 'ease',
            onComplete: () => 
            {
                this.animation()
            }
        })
    }

    update()
    {
        this.topLogo.model.visible = false
        this.middleLogo.model.visible = false
        this.bottomLogo.model.visible = false

        this.renderer.instance.setRenderTarget(this.backRenderTarget)
        this.renderer.instance.render(this.scene, this.camera.instance)

        this.topLogo.model.material.uniforms.uTexture.value = this.backRenderTarget.texture
        this.middleLogo.model.material.uniforms.uTexture.value = this.backRenderTarget.texture
        this.bottomLogo.model.material.uniforms.uTexture.value = this.backRenderTarget.texture
        this.topLogo.model.material.side = THREE.BackSide
        this.middleLogo.model.material.side = THREE.BackSide
        this.bottomLogo.model.material.side = THREE.BackSide

        this.topLogo.model.visible = true
        this.middleLogo.model.visible = true
        this.bottomLogo.model.visible = true

        this.renderer.instance.setRenderTarget(this.mainRenderTarget)
        this.renderer.instance.render(this.scene, this.camera.instance)

        this.topLogo.model.material.uniforms.uTexture.value = this.mainRenderTarget.texture
        this.middleLogo.model.material.uniforms.uTexture.value = this.mainRenderTarget.texture
        this.bottomLogo.model.material.uniforms.uTexture.value = this.mainRenderTarget.texture
        this.topLogo.model.material.side = THREE.FrontSide
        this.middleLogo.model.material.side = THREE.FrontSide
        this.bottomLogo.model.material.side = THREE.FrontSide

        this.renderer.instance.setRenderTarget(null)
    }
}